import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authReducer from '../features/auth/auth-slice';
import loginFormReducer from '../features/auth/login-slice';
import settingsReducer from '../features/settings/settings-slice';
//import saga from '../sagas/saga'

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware]

export const store = configureStore({
  reducer: {
    auth: authReducer,
    loginForm: loginFormReducer,
    settingsStore: settingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

//sagaMiddleware.run(saga)