import React, {useEffect, useState} from 'react';
import {dateTimeUnit, dateToYMD, endOf, startOf, ymdToDate} from "../../utils/utils";
import {DateTime} from 'luxon';
import {motion} from 'framer-motion';
import './spot.css';

import {useAppSelector} from '../../app/hooks';
import {
    accessToken,
} from '../auth/auth-slice';
import {describeArc} from "../../utils/utils";
import Calendar from "react-calendar";
import {ClipLoader} from "react-spinners";

import 'react-calendar/dist/Calendar.css';
import './Sample.css';
import {gql, useQuery, useLazyQuery} from "@apollo/client";
import {MongoDomain, MongoDayahead} from "../../shared/types";
import {favouriteZonePlans} from "../settings/settings-slice";

type SpotProps = {
    bidzone: string
}

const GET_SPOT_RANGE = gql`
  query GetDate(
    $country: String!
    $fromdate: String!
    $todate: String!
  ) {
    dayaheads(
      limit: 200
      query: { country: $country, date_gte: $fromdate, date_lte: $todate }
    ) {
      country
      date
      hour
      price
      tax
    }
  }
`;

const GET_DOMAINS = gql`
  query GetDomains {
    domains {
      _id
      country
      language
      TZ
      taxinfo
      title 
      mindate
      maxdate
    }
  }
`;

export function Spot(props: SpotProps) {

    const favPlans = useAppSelector(favouriteZonePlans);
    const zoneFavs = favPlans.filter((a:any) => a.zone === props.bidzone);
    const myFee = zoneFavs.length > 0 ? zoneFavs[0].fee : 0;

    const domain_data = useQuery(GET_DOMAINS, {
        pollInterval: 60000*5,
    });

    const [mysize, setMysize] = useState(260);
    const [pickdate, setPickdate] = useState(new Date(0));
    //const [seconddegs, setSeconddegs] = useState(0);
    //const [minutedegs, setMinutedegs] = useState(0);
    const [hourdegs, setHourdegs] = useState(0);
    const [bidzone, setBidzone] = useState("fi");
    const [allowrefetch, setAllowrefetch] = useState(0);
    const [seldayprices, setSeldayprices] = useState(Array.from({ length: 24 }, () => 0.0));
    const [seldaytax, setSeldaytax] = useState(0);

    const myAccessToken = useAppSelector(accessToken);

    function getMinDate() {
        const domains = domain_data?.data?.domains;
        let md = "";
        if (domains?.length > 0) {
            md = domains?.filter((a: MongoDomain) => a.country === bidzone)[0].mindate;
        }
        return ymdToDate(md);
    }

    function getMaxDate() {
        const domains = domain_data?.data?.domains;
        let md = "";
        if (domains?.length > 0) {
            md = domains?.filter((a: MongoDomain) => a.country === bidzone)[0].maxdate;
        }
        return ymdToDate(md);
    }

    function getLocale() {
        const domains = domain_data?.data?.domains;
        let locale = "";
        if (domains?.length > 0) {
            locale = domains?.filter((a: MongoDomain) => a.country === bidzone)[0].language;
        }
        return locale;
    }

    /*
    function checkMidnight() {
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const mins = currentDate.getMinutes();
        //const secs = currentDate.getMinutes();
        if (hours === 0 && mins === 0 && dateToYMD(currentDate) === getDiffDate(1, pickdate)) {
            console.log("go")
            return true;
        }
        return false;
    }
     */

    function getCurrentHour() {
        const ctz = countryTZ();
        if (ctz === "")
            return 12;
        const rezoned = getZoneDateTime(ctz)
        return rezoned.get('hour');
    }

    function setDegs() {
        const ctz = countryTZ();
        if (ctz === "")
            return;
        const rezoned = getZoneDateTime(ctz)
        const sr = rezoned.get('second') / 60;
        const mr = (sr + rezoned.get('minute')) / 60;
        const hr = (mr + rezoned.get('hour')) / 24;
        //setSeconddegs(sr * 360);
        //setMinutedegs(mr * 360);
        setHourdegs(hr * 360 + 180);
        //console.log(pickdate)
        //const mypick_day_after = new Date(pickdate);
        //mypick_day_after.setDate(mypick_day_after.getDate() + 1);
        //setPickdate(checkMidnight() ? mypick_day_after : pickdate);
        //setPickdate((d) => checkMidnight() ? mypick_day_after : d )
    }

    function euroToTaxedCents(price: number): number {
        const tax = dayTax(bidzone, pickdate);
        if (tax < 0) { // negative indicates price in euro instead of percentage
            return +(((price - tax) * 100).toFixed(0));
        }
        const tax_fact = 1 + tax / 100;
        return +((tax_fact * price * 100).toFixed(0))
    }

    function dayTax(bzone: string, d: Date) {
        const t = spotQuery?.data?.dayaheads?.filter((cn: MongoDayahead) => cn.country === bzone && cn.date === dateToYMD(d))[0]?.tax;
        if (t) {
            if (t !== seldaytax) {
                setSeldaytax(t);
            }
            return t;
        }
        return seldaytax;
    }

    const increaseRefetched = () => {
        setAllowrefetch((prevCounter) => prevCounter+1)
    }

    function daySpots(bzone: string, d: Date) {
        const prices = getCachedPrices(bzone, d);
        // check if spots are undefined indicating non-fetched/stale data
        if (prices.length === 0) {
            if (allowrefetch === 0) { // enough time passed since the last refetch
                spotQuery.refetch({
                        country: bidzone,
                        fromdate: prevMonday(d),
                        todate: nextSunday(d)
                    }
                );
                increaseRefetched();
            }
            return seldayprices; // keep showing selected day prices until new one is loaded
        } else {
            if (!arraysEqual(prices, seldayprices)) {
                setSeldayprices(prices);
            }
            return prices;
        }
    }

    function arraysEqual(a: number[], b: number[]) {
        a = Array.isArray(a) ? a : [];
        b = Array.isArray(b) ? b : [];
        return a.length === b.length && a.every((el, ix) => el === b[ix]);
    }

    function getCachedPrices(bzone: string, d: Date) {
        const prices = [];
        const daydata = spotQuery?.data?.dayaheads.filter((item: MongoDayahead) => item.country === bzone && item.date === dateToYMD(d));
        if (daydata?.length === 24) {
            for (let i = 0; i < 24; i++) {
                prices.push(daydata.filter((b: MongoDayahead) => b.hour === i)[0]?.price + myFee/100.0);
            }
        }
        return prices;
    }

    // update bidzone
    useEffect(() => {
        //console.log(props)
        //setCountry(props.lan)
        changeBidzone(props.bidzone)
        //changeBidzone(props.lan) //fixme
    }, [props.bidzone]);

    // secure that values are loaded when there is a valid access token or bidzone changes
    useEffect(() => {
        updateCalendarPick(pickdate);
    }, [bidzone, myAccessToken]);

    // run only on first mount
    useEffect(() => {
        updateCalendarPick(new Date());
    }, []);

    // set clock timer
    useEffect(() => {
        const id = setInterval(() => setDegs(), 1000); // start clock
        return () => clearInterval(id); // clean up timer on unmount
    }, [countryTZ()]);

    // run only on first mount - set time to wait between potential refetches from server
    useEffect(() => {
        const id = setInterval(() => setAllowrefetch(0), 60000); // start clock
        return () => clearInterval(id); // clean up timer on unmount
    }, []);

    // adjust time according to the new timezone
    useEffect(() => {
        setDegs();
    }, [countryTZ()]);

    function getUTC() {
        const utcDate = DateTime.local().toUTC().toISO();
        if (utcDate === null)
            return "";
        return utcDate;
    }

    function getZoneDateTime(tz: string) {
        const utc = getUTC();
        const zoned = DateTime.fromISO(utc).setZone(tz)
        return zoned;
    }

    function getZoneDate(tz: string) {
        const utc = getUTC();
        const zoned = DateTime.fromISO(utc).setZone(tz).toISODate()
        if (zoned === null)
            return "";
        return zoned;
    }

    function getZoneTime(tz: string) {
        const utc = getUTC();
        const zoned = DateTime.fromISO(utc).setZone(tz).toISOTime()
        if (zoned === null)
            return "";
        return zoned;
    }

    function findTZDate(d: Date, tz1: string, tz2: string) {
        const old_ymd = dateToYMD(d);
        const old_time = getZoneTime(tz1)
        const oldISO = old_ymd + "T" + old_time;
        const newDateTime = DateTime.fromISO(oldISO).setZone(countryTZ())
        const newYMD = newDateTime.setZone(tz2).toISODate();
        const newJSDate = new Date(newYMD === null ? new Date() : newYMD)
        return newJSDate;
    }

    function changeBidzone(bzone: string) {
        if (bzone !== bidzone) {
            const newJSDate = findTZDate(pickdate, countryTZ(), countryTZ2(bzone));
            updateCalendarPick(newJSDate);
            setBidzone(bzone)
        }
    }

    function getHourhandColor(d: Date) {
        let hcolor = "#EEEEEE";
        if (isTodaySelected()) {
            hcolor = "#000000";
        }
        return hcolor;
    }

    function prevMonday(searchdate: Date) {
        const prevMonday = startOf(dateTimeUnit.week, searchdate);
        const prevMondayYMD = dateToYMD(prevMonday);
        return prevMondayYMD;
    }

    function nextSunday(searchdate: Date) {
        const nextSunday = endOf(dateTimeUnit.week, searchdate);
        const nextSundayYMD = dateToYMD(nextSunday);
        return nextSundayYMD;

    }

    function updateCalendarPick(d: Date) {
        //console.log("updateCalendarPick");
        getDaySpots({ variables: {
                country: bidzone,
                fromdate: prevMonday(d),
                todate: nextSunday(d)
            }}
        );
        setPickdate(d);
    }

    const [getDaySpots, spotQuery] = useLazyQuery(GET_SPOT_RANGE, { notifyOnNetworkStatusChange: true });

    function handleCalendarPick(value: any, event: any): void {
        updateCalendarPick(value);
    }

    const fullViewbox = "0 0 " + mysize.toString() + " " + mysize.toString();
    const ringWidth = mysize * 0.1;

    function isTodaySelected() {
        const today = getZoneDate(countryTZ())
        return today === dateToYMD(pickdate);
    }

    const pricering = () => {
        const ss = daySpots(bidzone, pickdate);
        if (ss.length > 0) {
            return (
                ss.map((a: number, index: number) => {
                    const ii = index > 11 ? index - 12 : index + 12;
                    const numclass = "price price" + ii.toString();
                    return (
                        <div key={index}
                             style={!spotQuery.loading && !spotQuery.error && isTodaySelected() && index===getCurrentHour()?styles.nowprice:styles.notnowprice}
                             className={numclass}>{a > -9 ? euroToTaxedCents(a).toString() + String.fromCharCode(0x000A2) : ""}</div>
                    )
                })
            )
        }
    }

    const numberring = () => {
        const array24 = Array.from({length: 24}, (value, index) => index);
        return (
            array24.map((i) => {
                const numclass = "hour hour" + i.toString();
                let hl = "hourtext";
                const h = i > 11 ? i - 12 : i + 12;
                if (h > 14 && h < 22) {
                    hl = "hourtext2"
                }
                return (
                    <div key={i} className={numclass} style={isTodaySelected() && h>=getCurrentHour()?styles.future:styles.past}>
                        <div className={hl} key={i}>{h}</div>
                    </div>
                )
            })
        )
    }

    function priceColor(price: number): string {
        const cents = euroToTaxedCents(price);
        let basecolor = "#000000";
        let alpha = 200;
        if (cents < 60) {
            basecolor = "#FE00F6"; // purple
            alpha = (cents - 50) * 20;
        }
        if (cents < 50) {
            basecolor = "#FF8400"; // orange
            alpha = (cents - 37) * 18;
        }
        if (cents < 40) { // red
            basecolor = "#FF0033"
            alpha = (cents - 30) * 20;
        }
        if (cents < 30) { // yellow
            basecolor = "#FFFF00";
            alpha = (cents - 17) * 18;
        }
        if (cents < 20) { // green
            basecolor = "#0FE700";
            alpha = (cents - 8) * 20;
        }
        if (cents < 10) { // blue
            basecolor = "#001EFF";
            alpha = (cents) * 20;
        }
        let transparency = "00";
        if (alpha >= 0) {
            transparency = alpha.toString(16);
        }
        return basecolor + transparency;
    }

    const colorArc = (arc_color: string, rest_color: string, r_in: number, r_out: number, reach = 0.0, n_reach = 0.0) => {
        const array24 = Array.from({length: 24}, (value, index) => index);
        const now = getCurrentHour();
        const today = getZoneDate(countryTZ())
        return (
            array24.map((s, index) => {
                //let color = "#FFFFFF00"; // transparent
                let color = rest_color;
                let r1 = 0;
                let r2 = 0;
                let nr1 = 0;
                let nr2 = 0;
                if (dateToYMD(pickdate) === today ) {
                    if (index === 0)
                        nr1 = n_reach;
                    if (index + 1 === now)
                        nr2 = n_reach;
                }
                if (index >= now && dateToYMD(pickdate) === today ) {
                    color = arc_color;
                    if (index === now)
                        r1 = reach;
                    if (index === 23)
                        r2 = reach;
                }
                const a = (index + 12) * 15;
                const transition = { duration: 1, ease: "easeInOut"  };
                return (
                    <React.Fragment key={index}>
                        <motion.path
                            key={index}
                            d={describeArc(mysize / 2, mysize / 2, mysize * (r_out+r_in)/2, 0, a-0.5+r1+nr1, a + 15.5-r2-nr2)}
                            fill="none" strokeWidth={mysize * (r_out-r_in)}
                            initial={{ stroke: "#FFFFFF00" }}
                            animate={{ stroke: color }}
                            transition={transition}
                        />
                    </React.Fragment>
                )
            })
        )
    };

    const past_ring_edge = 0.431;
    const future_ring_width = 0.008;
    const future_line_width = 0.006;
    const past_line_width = 0.056;
    const past_ring_width = 0.05;

    const colorFilterArc = () => {
        return colorArc("#FFFFFF00", "#FFFFFF", past_ring_edge-future_ring_width, past_ring_edge, 0, 1);
    };

    const colorFilterArc2 = () => {
        return colorArc("#FFFFFF00", "#FFFFFF", past_ring_edge+past_ring_width, past_ring_edge+past_ring_width+0.005+future_ring_width, 0, 1);
    };

    const futureArc = () => {
        return colorArc("#000000", "#FFFFFF00", past_ring_edge-future_ring_width-0.001, past_ring_edge-future_ring_width-0.001+future_line_width, 0.3);
    };

    const futureArc2 = () => {
        return colorArc("#000000", "#FFFFFF00", past_ring_edge+past_ring_width+future_ring_width-0.001, past_ring_edge+past_ring_width+future_ring_width+future_line_width-0.001, 0.3);
    };

    const pastArc = () => {
        return colorArc("#FFFFFF00", "#000000", past_ring_edge, past_ring_edge+future_line_width, 2);
    };

    const pastArc2 = () => {
        return colorArc("#FFFFFF00", "#000000", past_ring_edge+past_ring_width, past_ring_edge+past_ring_width+future_line_width, 2);
    };

    const spotring = () => {
        const ss = daySpots(bidzone, pickdate);
        const transition = { duration: 0.3 };
        return (
            ss.map((s, index) => {
                let color = "#FFFFFF"; // white
                color = priceColor(s)
                const hour = index + 12;
                const frw = future_ring_width;
                return (
                    <motion.path
                    key={index}
                          d={describeArc(mysize / 2, mysize / 2, mysize * (0.5 - past_ring_width/2 - 0.015), 0, hour * 15, hour * 15 + 15)}
                          fill="none" stroke={color} strokeWidth={true?mysize * (past_ring_width+frw*2):0.5}
                        animate={{ stroke: color }}
                        transition={transition}>
                    </motion.path>
                )
            })
        )
    };

    const innerRingThickness = 3;

    const styles = {
        taxtextpos: {
            top: hourdegs % 360 > 300 || hourdegs % 360 < 60 ? "57%" : "16%",
        },
        mydflex: {
            zIndex: 3, // keep the d-flex container below the navigation bar
        },
        loadingIcon: {
            bottom: mysize * 0.5 - 55,
            left: mysize * 0.5 - 50,
        },
        innerRing: {
            width: mysize * (0.9-0.07),
            height: mysize * (0.9-0.07),
            top: mysize * (0.1+0.07) / 2 - innerRingThickness / 2,
            left: mysize * (0.1+0.07) / 2 - innerRingThickness / 2,
        },
        past: {
            fontWeight: "normal",
        },
        future: {
            fontWeight: "bold",
        },
        nowprice: {
            fontWeight: (isTodaySelected() && !spotQuery.loading && !spotQuery.error)?"bold":"normal",
            fontSize: "0.875rem",
        },
        notnowprice: {
            fontWeight: "normal",
        }
    }

    function taxtext() {
        const domains = domain_data?.data?.domains;
        const t = domains?.length > 0 ? domains.filter((a: MongoDomain) => a.country === bidzone)[0].taxinfo : "";
        let s = dayTax(bidzone, pickdate);
        if (s >= 0) {
            return t + " " + s.toString() + "%";
        } else { // negative value indicates tax in euro instead of percentage
            s = (s * -100).toFixed(1)
            return t + " " + s.toString() + String.fromCharCode(0x000A2);
        }
    }

    function countryTZ2(bzone: string) {
        const domains = domain_data?.data?.domains;
        const tz = domains?.length > 0 ? domains.filter((a: MongoDomain) => a.country === bzone)[0].TZ : "";
        return tz;
    }

    function countryTZ() {
        const domains = domain_data?.data?.domains;
        const tz = domains?.length > 0 ? domains.filter((a: MongoDomain) => a.country === bidzone)[0].TZ : "";
        return tz;
    }

    const transition = {
        backgroundColor: { duration: 1.0, ease: "easeInOut" },
        rotate: { duration: 1, type: "spring" }
    };
    return (
        <div className="d-flex align-items-center justify-content-center flex-row flex-wrap bd-highlight mt-md-3"
             style={styles.mydflex}>
            <div className="p-4 mx-md-3 order-md-2 pt-md-4">
                <div className="">
                <div className="dayclock koe d-flex justify-content-center" style={{width: mysize, height: mysize}}>
                    <div style={styles.taxtextpos} className="taxtext"><div>1/kWh</div><div>{taxtext()}</div></div>
                    <div className="myspinner" style={styles.loadingIcon}>
                        <ClipLoader
                            loading={spotQuery.loading || domain_data.loading}
                            size={mysize * 0.4}
                        />
                    </div>
                    <div className="centring">
                        {pricering()}
                    </div>
                    <div className="pricering">
                        <svg viewBox={fullViewbox} xmlns='http://www.w3.org/2000/svg'>
                            {spotring()}
                        </svg>
                    </div>
                    <div className="pricering">
                        <svg viewBox={fullViewbox} xmlns='http://www.w3.org/2000/svg'>
                            {colorFilterArc()}
                            {colorFilterArc2()}
                            {futureArc()}
                            {futureArc2()}
                            {pastArc()}
                            {pastArc2()}
                        </svg>
                    </div>
                    <motion.div className="dayhand minutehand"
                                animate={{ backgroundColor: getHourhandColor(pickdate), rotate: hourdegs > 360 ? hourdegs - 360 : hourdegs}}
                                transition={transition}
                     ></motion.div>
                    {numberring()}
                </div>
                </div>
            </div>
            <div className="p-0 order-md-1">
                    <div className="Sample__container">
                            <Calendar minDate={getMinDate()} maxDate={getMaxDate()}
                                      locale={getLocale()}
                                      onChange={handleCalendarPick}
                                      value={pickdate}/>
                    </div>
            </div>
        </div>
    );
}
