import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface Auth {
    username: string;
    email: string;
    access_token: string;
    refresh_token: string;
}

const initialState: { auth: Auth, isLoading: boolean, isError: boolean } = {
    auth: {
            username: "",
            email: "",
            access_token: "",
            refresh_token: "",
        },
    isLoading: false,
    isError: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.auth.username = action.payload.username;
            state.auth.email = action.payload.email;
            state.auth.access_token = action.payload.access_token;
            state.auth.refresh_token = action.payload.refresh_token;
        },
        setAccessToken: (state, action) => {
            state.auth.access_token = action.payload;
        },
        setAuthLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setAuthError: (state, action) => {
            state.isError = action.payload;
        }
    },
});

export const loggedinEmail = (state: RootState) => state.auth.auth.email;
export const accessToken = (state: RootState) => state.auth.auth.access_token;
export const email = (state: RootState) => state.auth.auth.email;
export const refreshToken = (state: RootState) => state.auth.auth.refresh_token;
export const isAuthLoading = (state: RootState) => state.auth.isLoading;
export const isAuthError = (state: RootState) => state.auth.isError;
export default authSlice.reducer;
export const {setUserDetails, setAccessToken, setAuthLoading, setAuthError} = authSlice.actions;
