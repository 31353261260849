// Returns a new Date object set to start of given unit
// For start of week, accepts any day as start

export function uniques(arr: any[]) {
    const a = [];
    if (arr) {
        for (let i = 0, l = arr.length; i < l; i++)
            if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
                a.push(arr[i]);
    }
    return a;
}

export function dateToYMD(date: Date) {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export function ymdToDate(ymd: string) {
    return new Date(ymd);
}

export function getDiffDate(diff: number, fromDate = new Date()): string {
    const daysAgo = new Date();
    daysAgo.setDate(fromDate.getDate() + diff);
    return dateToYMD(daysAgo);
}

export enum dateTimeUnit {
    second = 'second',
    minute = 'minute',
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
    decade = 'decade',
    century = 'century',
    millenium = 'millenium',
}

export function startOf(unit: dateTimeUnit, date = new Date(), weekStartDay = 1) {
    // Copy original so don't modify it
    const d = new Date(date);
    const e = new Date(d);
    e.setHours(23, 59, 59, 999);
    // Define methods
    const start = {
        second: (d: Date) => d.setMilliseconds(0),
        minute: (d: Date) => d.setSeconds(0, 0),
        hour: (d: Date) => d.setMinutes(0, 0, 0),
        day: (d: Date) => d.setHours(0, 0, 0, 0),
        week: (d: Date) => {
            start.day(d);
            d.setDate(d.getDate() - d.getDay() + weekStartDay);
            if (d > e) d.setDate(d.getDate() - 7);
        },
        month: (d: Date) => {
            start.day(d);
            d.setDate(1);
        },
        year: (d: Date) => {
            start.day(d);
            d.setMonth(0, 1);
        },
        decade: (d: Date) => {
            start.year(d);
            const year = d.getFullYear();
            d.setFullYear(year - year % 10);
        },
        century: (d: Date) => {
            start.year(d);
            const year = d.getFullYear();
            d.setFullYear(year - year % 100);
        },
        millenium: (d: Date) => {
            start.year(d);
            const year = d.getFullYear();
            d.setFullYear(year - year % 1000);
        }
    }
    start[unit](d);
    return d;
}

// Returns a new Date object set to end of given unit
// For end of week, accepts any day as start day
// Requires startOf
export function endOf(unit: dateTimeUnit, date = new Date(), weekStartDay = 1) {
    // Copy original so don't modify it
    let d = new Date(date);
    const e = new Date(date);
    e.setHours(23, 59, 59, 999);
    // Define methods
    const end = {
        second: (d: Date) => d.setMilliseconds(999),
        minute: (d: Date) => d.setSeconds(59, 999),
        hour: (d: Date) => d.setMinutes(59, 59, 999),
        day: (d: Date) => d.setHours(23, 59, 59, 999),
        week: (w: Date) => {
            w = startOf(dateTimeUnit.week, w, weekStartDay);
            w.setDate(w.getDate() + 6);
            end.day(w);
            d = w;
        },
        month: (d: Date) => {
            d.setMonth(d.getMonth() + 1, 0);
            end.day(d);
        },
        year: (d: Date) => {
            d.setMonth(11, 31);
            end.day(d);
        },
        decade: (d: Date) => {
            end.year(d);
            const y = d.getFullYear();
            d.setFullYear(y - y % 10 + 9);
        },
        century: (d: Date) => {
            end.year(d);
            const y = d.getFullYear();
            d.setFullYear(y - y % 100 + 99);
        },
        millenium: (d: Date) => {
            end.year(d);
            const y = d.getFullYear();
            d.setFullYear(y - y % 1000 + 999);
        }
    }
    end[unit](d);
    return d;
}

// Examples
/*
let d = new Date();

['second', 'minute', 'hour', 'day', 'week', 'month', 'year',
  'decade', 'century', 'millenium'].forEach(unit => {
  console.log(('Start of ' + unit).padEnd(18) + ': ' +
      startOf(unit, d).toString());
  console.log(('End of ' + unit).padEnd(18) + ': ' +
      endOf(unit, d).toString());
});

 */

export function describeArc(x: number, y: number, radius: number, spread: number, startAngle: number, endAngle: number) {
    const innerStart = polarToCartesian(x, y, radius, endAngle);
    const innerEnd = polarToCartesian(x, y, radius, startAngle);
    const outerStart = polarToCartesian(x, y, radius + spread, endAngle);
    const outerEnd = polarToCartesian(x, y, radius + spread, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
        "M", outerStart.x, outerStart.y,
        "A", radius + spread, radius + spread, 0, largeArcFlag, 0, outerEnd.x, outerEnd.y,
        "L", innerEnd.x, innerEnd.y,
        "A", radius, radius, 0, largeArcFlag, 1, innerStart.x, innerStart.y,
        "L", outerStart.x, outerStart.y, "Z"
    ].join(" ");

    return d;
}

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

/*
var path = describeArc(150, 150, 50, 30, 0, 50)
document.getElementById("p").innerHTML = path
document.getElementById("path").setAttribute('d',path)
 */

