import Dropdown from "react-bootstrap/Dropdown";
import React from "react";
import {Link} from "react-router-dom";

type SettingsProps = {
    bidzone: string;
}

export function Webhooks(props: SettingsProps) {
    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Webhooks for {props.bidzone.toUpperCase()}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to={"/" + props.bidzone + "/action"}>Create New Webhook</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
