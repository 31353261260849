import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './myswiper.css'

import {Spot} from "../features/spot/spot";
import {Settings} from "./settings";

import {useAppSelector} from '../app/hooks';
import {email} from "../features/auth/auth-slice";

export function Myswiper(props: {bidzone: string}) {
    const myEmail = useAppSelector(email);
    //const swiperRef: any = useRef();

    const settingsSlide = () => {
        return (
            <SwiperSlide>
                <Settings bidzone={props.bidzone}/>
            </SwiperSlide>
        )
    }

    return (
        <>
            <Swiper
                onSwiper={(swiper) => {
                    //swiperRef.current = swiper;
                }}
                modules={[Navigation]}
                navigation
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                        direction: "horizontal",
                    },
                    768: {
                        spaceBetween: 0,
                        slidesPerView: "auto",
                        direction: "vertical",
                    }
                }}
            >
                <SwiperSlide>
                    <Spot bidzone={props.bidzone}/>
                </SwiperSlide>
                {myEmail !== "anonymous@pslnol.com" && myEmail !== "" ? settingsSlide() : ""}
            </Swiper>
        </>
    )
}
