import {dasLoginUrl, dasRefreshTokenUrl, dasSignupUrl, dasVerifyEmailUrl} from "../../shared/baseUrl";
import {store} from "../../app/store";
import {setAccessToken, setAuthError, setAuthLoading, setUserDetails} from "./auth-slice";

import Axios from 'axios'

export const isEmail = (email: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const isPassword = (password: string) => password.length > 0;

// function uses axios to fetch data from our api
const callAPI = async ({url = "", method = "GET", headers = {}, data = {}}) => {
    return Axios({
        url,
        method,
        headers,
        data
    });
}

export async function verifyEmail(key: string) {
    if (key.length < 10) {
        return false;
    }
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    const data = {
        "tmp_verification_key": key,
    }
    try {
        const result =
            await callAPI({
                url: dasVerifyEmailUrl,
                method: 'POST',
                headers: headers,
                data: data
            })
        if (result.status === 200) {
            return true;
        }
    } catch (e) {
    }
    return false;
}

export async function signUp(email: string, password: string) {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    const data = {
        "username": email,
        "email": email,
        "password": password,
    }
    try {
        const result =
            await callAPI({
                url: dasSignupUrl,
                method: 'POST',
                headers: headers,
                data: data
            })
        if (result.status === 200) {
            return result.data;
        }
    } catch (e) {
    }
    return null;
}

async function userPwdToAccessToken(email: string, password: string) {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    const data = {
        "email": email,
        "password": password,
    }
    try {
        const result =
            await callAPI({
                url: dasLoginUrl,
                method: 'POST',
                headers: headers,
                data: data
            })
        if (result.status === 200) {
            return result.data;
        }
    } catch (e) {
    }
    return null;
}

async function refreshAccessToken(refToken: string) {
    if (refToken.length === 0) {
        return "";
    }
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    const data = {
        "refreshToken": refToken,
    }
    try {
        const result =
            await callAPI({
                url: dasRefreshTokenUrl,
                method: 'POST',
                headers: headers,
                data: data
            })
        if (result.status === 200) {
            return result.data.accessToken;
        }
    } catch (e) {
    }
    return "";
}

export const fetchNewToken = async (email: string, pass: string) => {
    store.dispatch(setAuthLoading(true));
    store.dispatch((setAuthError(false)));
    const refToken = store.getState().auth.auth.refresh_token;
    const newToken = await refreshAccessToken(refToken);
    if (newToken.length > 0) {
        store.dispatch(setAccessToken(newToken));
        store.dispatch(setAuthLoading(false));
        return true;
    }
    const newLogin = await userPwdToAccessToken(email, pass);
    if (newLogin) {
        store.dispatch(setUserDetails(newLogin))
        store.dispatch(setAuthLoading(false));
        return true;
    }
    store.dispatch(setAuthLoading(false));
    store.dispatch((setAuthError(true)));
    return false;
}

