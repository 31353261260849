import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    email: "",
    password: "",
    terms: false,
};


export const loginFormSlice = createSlice({
    name: "loginForm",
    initialState,
    reducers: {
        resetForm: (state) => {
            state = {...initialState};
        },
        updateFormEmail: (state, action) => {
            state.email = action.payload;
        },
        updateFormPassword: (state, action) => {
            state.password = action.payload;
        },
        updateFormTerms: (state, action) => {
            state.terms = action.payload;
        },
    }
});

export default loginFormSlice.reducer;
export const {resetForm, updateFormEmail, updateFormPassword, updateFormTerms} = loginFormSlice.actions;
