import React from 'react';
//import logo from './logo.svg';
//import './App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navigationbar from "./components/navigationbar";
import {Myswiper} from "./components/myswiper";
import {
  useLocation,
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";

function App() {
  const myPath = useLocation().pathname;
  let myBidzone = myPath;
  let myCommand = "";
  let myParam = "";
  const commandStartPos = myPath.indexOf("/",1);
  if (commandStartPos > 0) {
    myCommand = myPath.substring(commandStartPos+1)
    myBidzone = myPath.substring(1,commandStartPos);
    const paramStartPos = myPath.indexOf("/",commandStartPos+1);
    if (paramStartPos > 0) {
      myCommand = myPath.substring(commandStartPos+1, paramStartPos)
      myParam = myPath.substring(paramStartPos + 1)
    }
  } else {
    myBidzone = myBidzone.substring(1);
  }
  if (myBidzone === "") {
    myBidzone = "fi"
  }
  return (
      <div className="App">
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
          <Navigationbar bidzone={myBidzone} command={myCommand} param={myParam} />
          <Container>
            <Row className="justify-content-md-center">
              <Col md="auto" className="justify-content-center p-1 spot">
                <Myswiper bidzone={myBidzone} />
              </Col>
            </Row>
          </Container>
      </div>
  );
}

export default App;
