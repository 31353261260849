import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from "react-router-dom";
import React, {useState} from "react";
import getUnicodeFlagIcon from "country-flag-icons/unicode"
import {NavItem} from "react-bootstrap";
import './navigationbar.css';
import {gql, useQuery} from "@apollo/client";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {store} from "../app/store";
import {setUserDetails} from "../features/auth/auth-slice";
import {MongoDomain} from "../shared/types"
import Login from "../features/auth/login";
import {useSelector} from "react-redux";
import {resetFavouriteZonePlans} from "../features/settings/settings-slice";

type NavProps = {
    bidzone: string; // first two letters of bidzone indicate the two-letter country code
    command: string;
    param: string;
}

const GET_DOMAINS = gql`
  query GetDomains {
    domains {
      _id
      country
      language
      TZ
      taxinfo
      title 
    }
  }
`;

type DomainWithBidzones = {
    bidzones: string[];
} & MongoDomain

function sortByCountry(arr: MongoDomain[]) {
    let barr: MongoDomain[] = [];
    try {
        barr = [...arr]
        barr.sort(function(a:MongoDomain, b:MongoDomain){
            const x = a.country.toLowerCase();
            const y = b.country.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    } catch (e) {
        //console.log("Error:", e)
    }
    return barr;
}

// Convert a domains array to an object { country, bidzones } array
// where all 3-letter bidding zones are turned into a two-letter
// country and a list of bidzones within that country.
// Two-letter country codes that only have one biddinz zone
// are just added into the object array with an empty value for the
// 'bidzones' attribute
function domainToCountryWithBidzones(arr: MongoDomain[]) {
    const sorted = sortByCountry(arr);
    const ret: DomainWithBidzones[] = [];
    try {
        let prev = "";
        for (let i = 0; i < sorted.length; i++) {
            const s = sorted[i];
            const countrycode = s.country.substring(0,2);
            if (prev !== countrycode) {
                const o:  DomainWithBidzones = {country: countrycode, bidzones: [], _id: "", language: "", taxinfo: "", title: "", TZ: ""}
                ret.push(o)
            }
            if (s.country.length > 2) {
                ret.find((f) => f.country === countrycode)?.bidzones.push(s.country);
            }
            prev = countrycode;
        }
    } catch (e) {
    }
    //console.log(ret)
    return ret;
}

function Navigationbar(props: NavProps) {
    const domain_data = useQuery(GET_DOMAINS);
    const [navexpanded, setNavexpanded] = useState(false)
    const [buttonstate, setButtonstate] = useState("Login")
    const loggedInEmail = useSelector((state: any) => state.auth.auth.email);

    const logOut = () => {
        // reset current user/token details to trigger a new login to backend
        const user = {
            username: "",
            email: "",
            access_token: "",
            refresh_token: "",
        }
        store.dispatch(setUserDetails(user));
        store.dispatch(resetFavouriteZonePlans());
    }

    const handleLoginLogout = () => {
        if (loggedInEmail && loggedInEmail !== "anonymous@pslnol.com") {
            logOut();
        }
    }

    // generate country <-> title list from redux domains to display flag icon and title
    const titles: Record<string, string> = {}
    if (!domain_data.loading && !domain_data.error)
        for (let i = 0; i < domain_data.data.domains.length; i++) {
            titles[domain_data.data.domains[i].country] = domain_data.data.domains[i].title
        }
    const bidzone = props.bidzone;
    const command = props.command;
    const command_param = props.param;
    const title = titles[bidzone];

    let route = "/" + bidzone
    if (route === "/fi")
        route = "/";

    const routeItem = (country: string, index: number) => (
        <NavItem key={index}>
            <Nav.Link key={index} eventKey={index} as={Link} href={"/" + (country === "fi" ? "" : country)}
                      to={"/" + (country === "fi" ? "" : country)}
                      onClick={() => setNavexpanded(false)}>
                {getUnicodeFlagIcon(country.toUpperCase())} {' '} {country}
            </Nav.Link>
        </NavItem>
    )

    const routeDropdown = (d: DomainWithBidzones, index: number) => (
        <NavDropdown key={index} title={getUnicodeFlagIcon(d.country.toUpperCase()) + ' ' + d.country} id="collasible-nav-dropdown2">
            <div className="d-flex flex-md-column">
            {regions(d.bidzones, index)}
            </div>
        </NavDropdown>
    )

    const loginlogout = () => {
        if (loggedInEmail && loggedInEmail !== "anonymous@pslnol.com") {
            return (
                <Button variant="outline-primary" size="sm" onClick={() => handleLoginLogout()}>
                    Logout
                </Button>
            )
        } else {
            return (<div></div>)
        }
    }

    const regions = (rs: string[], top_index: number) => rs.map((r: string, index: number) => (
            <Nav.Link key={index + top_index * 10} eventKey={index} as={Link} href={"/" + r} to={"/" + r}
                      onClick={() => setNavexpanded(false)}>
                {getUnicodeFlagIcon(r.toUpperCase())} {' '} {r}
            </Nav.Link>
        )
    )

    const routes = domainToCountryWithBidzones(domain_data?.data?.domains)?.map((d: DomainWithBidzones, index: number) =>
        d.bidzones.length < 1 ? routeItem(d.country, index) : routeDropdown(d, index)
    )

    return (
        <Navbar expanded={navexpanded} collapseOnSelect fixed="top" expand="lg" bg="light" variant="light">
            <Login command={command} command_param={command_param}/>
            <Container>
                <Navbar.Brand href={route}>
                    <Container>
                        <Row className="align-items-center"><Col>{getUnicodeFlagIcon(props.bidzone.toUpperCase())}</Col>
                            <Col className="titletext">{title}</Col>
                        </Row>
                    </Container>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => navexpanded ? setNavexpanded(false) : setNavexpanded(true)}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav navbarScroll className="me-auto">
                        {domain_data.loading||domain_data.error? <p>loading...</p> : routes}
                    </Nav>
                    <Nav onClick={() => setNavexpanded(false)}>{loginlogout()}</Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigationbar;

