import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from "react-bootstrap/Modal";
import {fetchNewToken, signUp, isEmail, isPassword, verifyEmail} from "./auth-utils";
import {store} from "../../app/store";
import {setUserDetails} from "./auth-slice";
import {updateFormEmail, updateFormPassword, updateFormTerms} from "./login-slice";
import {useSelector} from "react-redux";
//import "./login.css";

type LoginProps = {
    command: string;
    command_param: string;
}

function Login(props: LoginProps) {
    const [validated, setValidated] = useState(false);
    const [showLogin, setShowLogin] = useState(props.command === "login");
    const [showSignup, setShowSignup] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [signedup, setSignedup] = useState(false);
    const email = useSelector((state: any) => state.loginForm.email);
    const password = useSelector((state: any) => state.loginForm.password);
    const terms = useSelector((state: any) => state.loginForm.terms);
    const [isTransient, setTransient] = useState(false);
    const [failedSignup, setFailedSignup] = useState(false);
    const [isVerificationCommand, setIsVerificationCommand] = useState(false);

    useEffect(() => {
        if (props.command === "verify") {
            setIsVerificationCommand(true);
        }
    }, []);

    useEffect(() => {
        if (isVerificationCommand) {
            const doVerify = async () => {
                const retval = await verifyEmail(props.command_param);
                setVerificationStatus(retval);
                setShowVerification(true)
            }
            doVerify();
        }
    }, [isVerificationCommand]);

    useEffect(() => {
        function simulateNetworkRequest() {
            return new Promise((resolve) => setTimeout(resolve, 2000));
        }

        if (isTransient) {
            simulateNetworkRequest().then(() => {
                setTransient(false);
            });
        }
    }, [isTransient]);

    const logOut = () => {
        // reset current user/token details to trigger a new login to backend
        const user = {
            username: "",
            email: "",
            access_token: "",
            refresh_token: "",
        }
        store.dispatch(setUserDetails(user));
    }

    const handleClose = async () => {
        setLoading(true);
        setShowLogin(false);
        setShowSignup(false);
        setShowVerification(false);
    }

    const handleSubmit = async (event: any) => {
        setLoading(true)
        if (event === null) {
            setShowLogin(false)
            return
        }
        setSubmitted(true);
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (isEmail(email)) {
            logOut();
            const retval = await fetchNewToken(email, password);
            if (retval) {
                setShowLogin(false)
            } else {
                setTransient(true)
            }
        }

        setLoading(false)
        setValidated(true);
    };

    const handleSignup = async (event: any) => {
        setLoading(true)
        if (event === null) {
            setShowSignup(false)
            return
        }
        setSignedup(true);
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (terms && isEmail(email) && isPassword(password)) {
            const retval = await signUp(email, password)
            if (retval === null) {
                setTransient(true);
                setFailedSignup(true);
            } else {
                setShowSignup(false)
            }
        }

        setLoading(false)
        setValidated(true);
    };

    const handleClickSignup = async (event: any) => {
        setLoading(false)
        setShowLogin(false)
        setShowSignup(true)
        //store.dispatch(updateFormEmail(""));
        //store.dispatch(updateFormPassword(""));
        //store.dispatch(updateFormTerms(false));
    };

    const handleClickLogin = async (event: any) => {
        setLoading(false)
        setShowLogin(true)
        setShowSignup(false)
        setShowVerification(false)
    };

    const signUpModal = () => {
        return (
            <Modal show={showSignup} onHide={(): Promise<void> => handleSignup(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Register to Dailyspot</Modal.Title>
                </Modal.Header>
                <Form validated={validated} noValidate onSubmit={handleSignup}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="10" controlId="validationCustom02">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    autoFocus
                                    onChange={(e) => {
                                        setFailedSignup(false);
                                        store.dispatch(updateFormEmail(e.target.value));
                                    }}
                                    placeholder=""
                                    isValid={isEmail(email) && !failedSignup}
                                    isInvalid={(!isEmail(email) || failedSignup) && signedup}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {failedSignup ? "Please, provide a different email address!" : "Please, provide a valid email!"}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="10" controlId="validationCustom03">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={(e) => {
                                        store.dispatch(updateFormPassword(e.target.value));
                                    }}
                                    isValid={isPassword(password) && !failedSignup}
                                    isInvalid={!isPassword(password) && signedup}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a password!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <div className="">
                                <Form.Check
                                    label="Agree to terms and conditions"
                                    onChange={(e) => {
                                        store.dispatch(updateFormTerms(e.target.checked));
                                    }}
                                    required
                                    feedback="You must agree before submitting."
                                    feedbackType="invalid"
                                />
                            </div>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Form.Group>
                            <Button
                                type="submit"
                                disabled={loading}
                                onClick={!loading ? handleSignup : undefined}
                            >{loading ? "Loading..." : "Signup"}</Button>
                        </Form.Group>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    const successModal = () => {
        return (
            <Modal show={showVerification && verificationStatus === true} onHide={(): Promise<void> => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Successfully registered!</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                    <div className="p-2">
                        <Button
                            variant="link"
                            type="button"
                            onClick={!loading ? handleClickLogin : undefined}
                        >Login</Button>
                    </div>
                    </Modal.Body>
                <Form onSubmit={handleClose}>
                </Form>
            </Modal>
        )
    }

    const failureModal = () => {
        return (
            <Modal show={showVerification && verificationStatus === false} onHide={(): Promise<void> => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Email verification failed, invalid token!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                </Modal.Body>
                <Form onSubmit={handleClose}>
                </Form>
            </Modal>
        )
    }

    const loginModal = () => {
        return (
            <Modal show={showLogin} onHide={(): Promise<void> => handleSubmit(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Login to Dailyspot</Modal.Title>
                    <div className="p-2">
                        <Button
                            variant="link"
                            type="button"
                            onClick={!loading ? handleClickSignup : undefined}
                        >Sign Up</Button>
                    </div>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="10" controlId="validationCustom02">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    autoFocus
                                    onChange={(e) => {
                                        store.dispatch(updateFormEmail(e.target.value));
                                    }}
                                    isValid={isEmail(email)}
                                    isInvalid={!isEmail(email) && submitted}
                                />
                                <Form.Control.Feedback type="invalid">
                                    "Please, provide a valid email address!"
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="10" controlId="validationCustom03">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={(e) => {
                                        store.dispatch(updateFormPassword(e.target.value));
                                    }}
                                    placeholder=""
                                    isValid={isPassword(password)}
                                    isInvalid={!isPassword(password) && submitted}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a password!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={loading ? "secondary" : (isTransient ? "danger" : "primary")}
                            type="submit"
                            disabled={loading || isTransient || (!isEmail(email) || !isPassword(password)) && submitted}
                            onClick={!loading ? handleSubmit : undefined}
                        >{loading ? "Loading..." : (isTransient ? "Login failed" : "Login")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    return (
        <>
            {signUpModal()}
            {loginModal()}
            {successModal()}
            {failureModal()}
        </>
    );
}

export default Login;

