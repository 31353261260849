import {createSlice} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";

interface ZonePlan {
    zone: string;
    plan: string;
    fee: number;
}

const initialState: { favouritePlans: ZonePlan[] } = {
    favouritePlans: [], // currently selected transmission plans / zone (country in most cases)
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        resetFavouriteZonePlans: (state) => {
            //state = {...initialState};
            state.favouritePlans = [];
        },
        upsertFavouriteZonePlan: (state, action) => {
            //console.log(action)
            const foundIndex = state.favouritePlans.findIndex((x:ZonePlan) => x.zone === action.payload.zone);
            //console.log(foundIndex)
            if (foundIndex > -1) {
                state.favouritePlans[foundIndex] = action.payload;
            } else {
                state.favouritePlans.push(action.payload);
            }
        },
    }
});

export default settingsSlice.reducer;
export const {resetFavouriteZonePlans, upsertFavouriteZonePlan} = settingsSlice.actions;
export const favouriteZonePlans = (state: RootState) => state.settingsStore.favouritePlans;
