import React, {useEffect, useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useAppSelector} from "../../app/hooks";
import {email, setUserDetails} from "../auth/auth-slice";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {useSelector} from "react-redux";
import {store} from "../../app/store";
import {favouriteZonePlans, upsertFavouriteZonePlan} from "./settings-slice";
import {uniques} from "../../utils/utils";

type ProfileProps = {
    bidzone: string;
}

const GET_TRANSFER_PLANS = gql`
    query GetTransmissionPlans {
        transmissionplans {
            _id
            zone
            plan
            periodFees {
                date_start
                date_end
                time_start
                time_end
                fee
            }
        }
    }
`;

const PUT_FAVOURITE_PLANS = gql`
    mutation PutFavouritePlans (
        $favplans: [FavouritePlanInput]
        ) {
        upsertFavouritePlans (
            favouritePlansInput: $favplans
        ) {
            zone
            plan
        }
    }
`;

const GET_FAVOURITE_PLANS = gql`
    query GetFavouritePlans {
        favouriteplans {
            zone
            plan
        }
    }
`;

export function Profile(props: ProfileProps) {

    // define lazy query for Transmission Plans
    const zonePlanQuery = useQuery(GET_TRANSFER_PLANS, {notifyOnNetworkStatusChange: true});

    const favPlanQuery = useQuery(GET_FAVOURITE_PLANS, {notifyOnNetworkStatusChange: true});

    const [putFavouritePlans, {data, loading, error}] = useMutation(PUT_FAVOURITE_PLANS, {notifyOnNetworkStatusChange: true});

    function getPlanFee(z: string, p:string) {
        const fromTable = zonePlanQuery?.data?.transmissionplans.filter((zp: any) => zp.zone === z && zp.plan === p)[0]?.periodFees[0]?.fee;
        if (fromTable === undefined) {
            return 0;
        } else {
            return fromTable;
        }
    }

    // Load local store when component first loaded but not after that
    // as changes are applied to local store and pushed to backen
    useEffect(() => {
        const favPlans = store.getState().settingsStore.favouritePlans; // get local favourite plans
        if (favPlans.length === 0) {
            for (let i = 0; i < favPlanQuery.data?.favouriteplans.length; i++) {
                const favPlan = favPlanQuery.data.favouriteplans[i];
                const favFee = getPlanFee(favPlan.zone, favPlan.plan);
                store.dispatch(upsertFavouriteZonePlan({zone: favPlan.zone, plan: favPlan.plan, fee: favFee}));
            }
        }
    }, [favPlanQuery.data, zonePlanQuery.data]);

    const graphQlZonePlans = (zone: string) => {
        if (zonePlanQuery.loading) {
            return [{plan: "loading..."}];
        }
        if (zonePlanQuery.error) {
            return [{plan: "no transfer fee"}];
        }
        const zonePlans = zonePlanQuery?.data?.transmissionplans.filter((z: any) => z.zone === zone)
            zonePlans.push({zone: zone, plan: "Transfer fee", fee: 0});

        return zonePlans;
    }

    const doPlans = (zone: string) => {
        return (
            graphQlZonePlans(zone)?.map((z: any) => {
                return (
                    <Dropdown.Item key={z.plan} eventKey={z.plan}>{z.plan}</Dropdown.Item>
                )
            })
        )
    }

    const favplans = useAppSelector(favouriteZonePlans);
    const handleSelect = (e: any) => {
        const myFee = getPlanFee(props.bidzone, e) // get fee of current selection from graphQL query
        store.dispatch(upsertFavouriteZonePlan({zone: props.bidzone, plan: e, fee: myFee})); // save selected zone/plan/fee to local store
        const favPlans = store.getState().settingsStore.favouritePlans; // get all local favourite plans
        const zpPlans = favPlans.map(a => { return {zone: a.zone, plan: a.plan}}); // filter out fee
        putFavouritePlans({ variables: { favplans: zpPlans } }); // and mutate back end
    }

    const myEmail = useAppSelector(email);
    const favPlans = useAppSelector(favouriteZonePlans); // get all local favourite plans
    const zoneFavs = favPlans.filter((a:any) => a.zone === props.bidzone); // filter out favourite plan for this zone
    const myPlan = zoneFavs.length > 0 ? zoneFavs[0].plan : "Transfer fee"; // if not available, use default as current selection
    const myFee = getPlanFee(props.bidzone, myPlan) // fetch corresponding fee
    return (
        <Form>
            <Form.Group as={Row} className="mb-3" controlId="formGroupEmail">
                <Form.Label column xs={5} sm={3}>Email</Form.Label>
                <Col xs={7} sm={6}>
                    <Form.Control type="email" defaultValue={myEmail} disabled={true}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formGroupEmail">
                <Col xs={5} sm={3}>
                    <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            {myPlan}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {doPlans(props.bidzone)}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col xs={4} sm={2}>
                    <Form.Control onChange={e => console.log("")} value={(myFee?myFee:0) + String.fromCharCode(0x000A2)}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formGroupEmail">
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formGroupEmail">
                <Form.Label column xs={5} sm={3}>Delete account</Form.Label>
                <Col xs={3}>
                    <Button variant="danger">Danger</Button>{' '}
                </Col>
            </Form.Group>
        </Form>
    );
}

