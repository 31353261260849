import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-social/bootstrap-social.css';
import {dasGraphQLUrl} from "./shared/baseUrl";

import {fetchNewToken} from "./features/auth/auth-utils";

// Import GraphQL dependencies
import {
    ApolloLink,
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    from,
    fromPromise,
} from "@apollo/client";

import {onError} from "@apollo/client/link/error";

const httpLink = new HttpLink({
    uri: dasGraphQLUrl,
});

type Headers = {
    authorization?: string
}

const authLink = new ApolloLink((operation, forward) => {
    const accessToken = store.getState().auth.auth.access_token;
    operation.setContext(({headers}: { headers: Headers }) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`,
        },
    }))
    return forward(operation)
})

export type AnyFunction = (...args: any[]) => any;

let isRefreshing = false
let pendingRequests: AnyFunction[] = []

const setIsRefreshing = (value: boolean) => {
    //console.log("setIsRefreshing", value)
    isRefreshing = value
}

const addPendingRequest = (pendingRequest: AnyFunction) => {
    //console.log("index: addPendingRequest")
    pendingRequests.push(pendingRequest)
}

const resolvePendingRequests = () => {
    //console.log("index: resolvePendingRequests", pendingRequests.length)
    pendingRequests.map((callback) => callback())
    pendingRequests = []
}

const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
    if (networkError?.message?.includes("403") || networkError?.message?.includes("401")) {
        //console.log("index:", networkError?.message);
        if (!isRefreshing) {
            //console.log("index: is not refreshing")
            setIsRefreshing(true)
            return fromPromise(
                fetchNewToken("anonymous@pslnol.com", "koe").catch(() => {
                    console.log("resolve fromPromise")
                    resolvePendingRequests()
                    setIsRefreshing(false)
                    return forward(operation)
                }),
            ).flatMap(() => {
                //console.log("resolve from flatMap")
                resolvePendingRequests()
                setIsRefreshing(false)
                return forward(operation)
            })
        } else {
            //console.log("index: is refreshing")
            return fromPromise(
                new Promise<void>((resolve) => {
                    addPendingRequest(() => resolve())
                }),
            ).flatMap(() => {
                return forward(operation)
            })
        }
    }
})

// Configure the ApolloClient to connect to your app's GraphQL endpoint
const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
