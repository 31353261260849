import React, {useState} from 'react';
import Card from "react-bootstrap/Card";
import {useAppSelector} from "../app/hooks";
import {email} from "../features/auth/auth-slice";
import {Webhooks} from "../features/webhook/webhook";
import Nav from "react-bootstrap/Nav";
import {Profile} from "../features/settings/profile";
import {Link} from "react-router-dom";
import {Alert} from "react-bootstrap";

type SettingsProps = {
    bidzone: string;
}

export function Settings(props: SettingsProps) {
    const myEmail = useAppSelector(email);
    const [seltab, setSeltab] = useState("profile");
    const handleSelect = (e: any) => {
        setSeltab(e);
    }

    const control = () => {
        return (
            <div>
                <Alert variant="primary">
                    <p>
                        Utilities that enable controlling external devices.
                    </p>
                </Alert>
                <Webhooks bidzone={props.bidzone}/>
            </div>
        )
    }

    const notification = () => {
        return (
            <div>
                <Alert variant="primary">
                    <p>
                        Generate reports and notifications.
                    </p>
                </Alert>
            </div>
        )
    }

    const profile = () => {
        return (
            <div>
                <Alert variant="primary">
                    <p>
                        Account information and basic profile settings.
                    </p>
                </Alert>
                <Profile bidzone={props.bidzone}/>
            </div>
        )
    }

    return (
        <Card>
            <Card.Header>
                <Nav justify variant="tabs" activeKey={seltab} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link eventKey="profile" as={Link} to={"/" + props.bidzone + "/settings"}>Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="control" as={Link} to={"/" + props.bidzone + "/control"}>Control</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="notifications" as={Link} to={"/" + props.bidzone + "/notifications"}>Notifications</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Card.Header>
            <Card.Body>
                {seltab === "control" ? control() : ""}
                {seltab === "notifications" ? notification() : ""}
                {seltab === "profile" ? profile() : ""}
            </Card.Body>
        </Card>
    );
}

